/* ar-brown App (C) 2021 arbrown.co.jp All rights reserved.
 *******************************************************************************
 * Program ID:  InventorySearch.js
 * $Revision:   Ver1.0
 * $Modtime:    $
 *******************************************************************************
 * Author      (作成／更新年月日、担当者)
 *  2021/08/01 Rayoo)li : 新規作成
 *
 * Summary InventorySearch画面
 *
 * Instructions / Preconditions
 *  なし
 *
 *******************************************************************************
 */
import './style/ar.css';
// page content
import Meta from '../components/Meta'
import userAuth from '../components/userAuth'
import React, { useEffect, useReducer } from 'react';
/*コンテナ（画面レイアウト）デザイン*/
import { Container, Row, Col } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter, selectFilter, numberFilter, Comparator } from 'react-bootstrap-table2-filter';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Select, MenuItem, FormControl, InputLabel, OutlinedInput } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
// import { Circle } from 'react-bootstrap-icons';
// import { ExclamationTriangle } from 'react-bootstrap-icons';
// import { XLg } from 'react-bootstrap-icons';
// import { Telephone } from 'react-bootstrap-icons';
/*  GraphQL API Acess */
import API, { graphqlOperation } from '@aws-amplify/api';
import { agencyGroupSortList, customerByeMail, AdminAgencyPriceGroupSortList } from '../graphql/queries';
/*  AWS標準設定 with Login Auth. */
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from '../aws-exports';
Amplify.configure(awsconfig);

// page content
const pageTitle = 'ARB-SIP（在庫・価格照会システム）'
const { SearchBar, ClearSearchButton } = Search;

// Initialize for Inventory info.
const QUERY = 'QUERY';
const initialInventoryState = { Invs: [] };
const reducer = (state, action) => {
  switch (action.type) {
    case QUERY:
      return { ...state, Invs: action.Invs };
    default:
      return state;
  }
};
const initialSupplier = {};
const reducerSupplier = (state, action) => {
  let obj = {};
  switch (action.type) {
    case QUERY:
      for (let i = 0; i < action.Invs.length; i++) {
        let cur = action.Invs[i];
        let curn = action.Invs[i];
        obj[cur] = curn;
      }
      return { ...state, ...obj };
    default:
      return state;
  }
};
//const { ExportCSVButton } = CSVExport;
// 下拉框
// let selectOptions = {
//   'Epicentre/Lucigen': 'Epicentre/Lucigen',
//   'S': 'Epicentre',
//   'Z': 'そのた'
// };
// const headerStyle = { backgroundColor: '#c8e6c9' }
const headerStyle = { backgroundColor: '#D3D3D3' }
const headerSortingStyle = { backgroundColor: '#BEBEBE' };
function getStyles(name, priceGroup, theme) {
  return {
    fontWeight:
      priceGroup.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const ITEM_HEIGHT = 100;
const ITEM_PADDING_TOP = 9;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};
function InventorySearch() {
  const [inventories, setInv] = useReducer(reducer, initialInventoryState);
  let [selectOptions, setSupplier] = useReducer(reducerSupplier, initialSupplier);
  const [adminAuthFlg, setAdminAuthFlgFlg] = React.useState(Object);
  const handleSetAdminAuthFlg = (Object) => {
    setAdminAuthFlgFlg(Object);
  };
  // selectOptionsArr = [{
  //   value: 0,
  //   label: 'good'
  // }, {
  //   value: 1,
  //   label: 'Bad'
  // }, {
  //   value: 2,
  //   label: 'unknown'
  // }];
  // selectOptions = {
  //   'Z': 'そのた'
  // };
  let columns = [
    { dataField: 'id', text: 'ID', hidden: true },
    // { dataField: 'Agency_Price_Group', text: 'Group', filter: textFilter(), sort: true, hidden: true },
    {
      dataField: 'Product_Code', text: '製品コード', width: '200px', filter: textFilter({
        placeholder: '',
        className: '',
        defaultValue: '',
        comparator: Comparator.LIKE, // default is Comparator.LIKE
        caseSensitive: true,
        style: { backgroundColor: '', margin: '0px', width: '100px' },
        delay: 500,
      }), sort: true, headerSortingStyle, headerStyle, type: 'string'
    },
    // {
    //   dataField: 'Supplier', text: '製造元', formatter: cell => selectOptionsArr.filter(opt => opt.value === cell)[0].label || '', filter: selectFilter({
    //     options: selectOptionsArr,
    //     //defaultValue: 'Epicentre/Lucigen',
    //     style: { backgroundColor: '', margin: '0px', width: '150px' },
    //   }), sort: true,
    //   headerSortingStyle, headerStyle, type: 'string'
    // },
    {
      dataField: 'Supplier', text: '製造元',
      formatter: cell => selectOptions[cell],
      filter: selectFilter({
        options: selectOptions,
        style: { backgroundColor: '', margin: '0px', width: '150px' },
      }),
      sort: true,
      headerSortingStyle, headerStyle, type: 'string'
    },
    { dataField: 'Product_Name_j', text: '製品名（和名）', filter: textFilter({ style: { backgroundColor: '', margin: '0px', width: '150px' }, }), sort: true, headerSortingStyle, headerStyle, type: 'string' },
    { dataField: 'Product_Name_e', text: '製品名（英名）', headerSortingStyle, style: { margin: '0px', width: '200px' }, headerStyle, type: 'string' },
    { dataField: 'Capacity_Display', text: '容量', headerStyle },
    { dataField: 'Catarog_Price', text: '定価', style: { margin: '0px', width: '100px' }, headerStyle, align: 'right', headerAlign: 'left' },
    { dataField: 'Wholes_Rate', text: '仕切率', headerStyle, style: { margin: '0px', width: '80px' }, align: 'right' },
    { dataField: 'Wholes_Price', text: '仕切値', headerStyle, align: 'right' },
    {
      dataField: 'Campaign_price', text: 'キャンペーン価格', style: { margin: '0px', width: '140px' }, headerStyle, align: 'right',
      isDummyField: true,
      formatter: (cellContent, row) => {
        if (row.Campaign_price === null) {
          return (
            <span>―</span>
          );
        } else {
          return (
            <span>
              {row.Campaign_price}</span>
          );
        }
      }
    },
    {
      dataField: 'Campaign_partition_rate', text: 'キャンペーン仕切率', align: 'right', style: { margin: '0px', width: '140px' }, headerStyle,
      isDummyField: true,
      formatter: (cellContent, row) => {
        if (row.Campaign_partition_rate === null) {
          return (
            <span>―</span>
          );
        } else {
          return (
            <span>
              {row.Campaign_partition_rate}</span>
          );
        }
      }
    },
    {
      dataField: 'Quantity', text: '在庫数', align: 'right', type: 'number', headerStyle, filter: numberFilter({
        defaultValue: { number: 0, comparator: Comparator.GE },
        comparators: [Comparator.EQ, Comparator.GE, Comparator.LE],
        style: { display: 'inline-grid' },
        className: 'custom-numberfilter-class',
        comparatorStyle: { backgroundColor: 'antiquewhite', margin: '0px', width: '70px' },
        comparatorClassName: 'custom-comparator-class',
        numberStyle: { backgroundColor: 'cadetblue', margin: '0px', width: '100px' },
        numberClassName: 'custom-number-class'
      }),
      // isDummyField: true,
      // formatter: (cellContent, row) => {
      //   // if (row.Quantity > 5) {
      //   //   return (
      //   //     <h3>
      //   //       {/* <span className="label label-success"><CircleFill/></span> */}
      //   //       <Circle className="label-success" />
      //   //     </h3>
      //   //   );
      //   // } else if (row.Quantity > 0) {
      //   //   return (
      //   //     <h3>
      //   //       {/* <span className="label label-warning"><ExclamationTriangleFill/></span> */}
      //   //       <ExclamationTriangle className="label-warning" />
      //   //     </h3>
      //   //   );
      //   // } else
      //   if (row.Quantity <= 0) {
      //     return (
      //       <span>
      //         {/* <span className="label label-danger"><XCircleFill/></span> */}
      //         {row.Quantity}<Telephone className="label-success" />
      //       </span>
      //     );
      //   } else {
      //     return (
      //       <span>
      //         {row.Quantity}</span>
      //     );
      //   }
      // }
    },
    { dataField: 'Delivery_Term', style: { margin: '0px', width: '120px' }, text: '欠品時目安納期', headerStyle },
    { dataField: 'Storage_Temp', style: { margin: '0px', width: '100px' }, text: '保管温度', headerStyle },
    { dataField: 'LawsRegulations', style: { margin: '0px', width: '60px' }, text: '法規制', headerStyle },
  ];
  useEffect(() => {
    // 非同期型（async）で在庫情報をagencyGroupInvListからAgency_Price_GroupをKeyに取得
    // 1) Auth.currentUserInfo() から、email情報を取得して、customerByeMailからeMailをKeyにAgent情報を取得
    //  ※agencyGroupIDはAgent.jsで一度取得しているから再利用可能なはず！
    // 2) agencyGroupIDを取得して、これをKeyにagencyGroupInvListから在庫情報を取得
    async function listInvData() {
      // get the Login user infomation. Set to user
      // const { accessToken } = await Auth.currentSession();
      // const cognitogroups = accessToken.payload['cognito:groups'];
      // const userAuth = cognitogroups[0];
      const userAuths = await userAuth();
      let agentGroupID;
      if (userAuths === '1') {
        handleSetAdminAuthFlg(true);
        const user = await Auth.currentUserInfo();
        const agencyPriceGroup = await API.graphql(graphqlOperation(customerByeMail, { Agency_Email: user.attributes.email }));
        const agentinfo = agencyPriceGroup.data.customerByeMail['items'];
        if (agentinfo.length > 0) {
          agentGroupID = agentinfo[0].Agency_Price_Group;
        }
      } else {
        // admin
        handleSetAdminAuthFlg(false);
        const AdminAgencyPriceList = await API.graphql(graphqlOperation(AdminAgencyPriceGroupSortList, { Admin_Group: "admin" }));
        if (AdminAgencyPriceList.data.AdminAgencyPriceGroupSortList.items.length > 0) {
          agentGroupID = AdminAgencyPriceList.data.AdminAgencyPriceGroupSortList.items[0].Admin_Agency_Price_Group
          setAgencyPriceGroup({ type: QUERY, Invs: AdminAgencyPriceList.data.AdminAgencyPriceGroupSortList.items });
        }
      }
      const InvData = await API.graphql(graphqlOperation(agencyGroupSortList, { Agency_Price_Group: agentGroupID, sortDirection: "DESC", limit: 10000 }));
      let nextToken = InvData.data.AgencyGroupSortList.nextToken;
      let dataAll = InvData.data.AgencyGroupSortList.items
      while (nextToken !== null) {
        let InvDataSec = await API.graphql(graphqlOperation(agencyGroupSortList, { Agency_Price_Group: agentGroupID, sortDirection: "DESC", limit: 10000, nextToken: nextToken }));
        nextToken = InvDataSec.data.AgencyGroupSortList.nextToken;
        for (let i = 0; i < InvDataSec.data.AgencyGroupSortList.items.length; i++) {
          dataAll.push(InvDataSec.data.AgencyGroupSortList.items[i]);
        }
      }
      let allSupplier = [];
      for (let i = 0; i < dataAll.length; i++) {
        allSupplier.push(dataAll[i].Supplier);
      }
      let distinctSupplier = []
      let hash = {}
      distinctSupplier = allSupplier.reduce((item, next) => {
        hash[next] ? '' : hash[next] = true && item.push(next)
        return item
      }, [])
      setSupplier({ type: QUERY, Invs: distinctSupplier });
      setInv({ type: QUERY, Invs: dataAll });
      handleSetAuthFlg(1)
    }
    listInvData();
  }, []);

  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: 5,
    pageStartIndex: 1,
    showTotal: true,
    // alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: true, // Hide the going to First and Last page button
    // hideSizePerPage: false, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next page',
    lastPageTitle: 'Last page',
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [{
      text: '5', value: 5
    }, {
      text: '10', value: 10
    }, {
      text: '20', value: 20
    }, {
      text: '100', value: 100
    }] // A numeric array is also available. the purpose of above example is custom the text
    //  , {
    //     text: 'All', value: inventories.Invs.length
    //   }
  };
  const CustomToggleList = ({
    columns,
    onColumnToggle,
    toggles,
  }) => (
    <div className="btn-group btn-group-toggle btn-group-vertical" data-toggle="buttons">
      {
        <div>{
          columns
            .map(column => ({
              ...column,
              toggle: toggles[column.dataField]
            }))
            .map(column => (
              !column.hidden ?
                <button
                  type="button"
                  key={column.dataField}
                  className={`btn btn-secondary list-custom-class ${column.toggle ? 'active' : 'btn-danger'}`}
                  data-toggle="button"
                  aria-pressed={column.toggle ? 'true' : 'false'}
                  onClick={() => onColumnToggle(column.dataField)}
                  btnClassName="list-btn-custom-class btn-sm"
                >{column.text}
                </button> : ''
            ))
        } </div>
      }
    </div>
  );
  const defaultSorted = [{
    dataField: 'id',
    order: 'desc'
  }];

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    bgColor: '#FFFAFA',
    //onSelectAll: handleOnSelectAll
  };
  const [authFlg, setAuthFlg] = React.useState(Object);
  const handleSetAuthFlg = (Object) => {
    if (Object === 1) {
      setAuthFlg({
        fileName: '商品一覧（チェックのみ）.csv',
        noAutoBOM: false,
        blobType: 'text/plain;charset=Shift-JIS',
        exportAll: true,
        onlyExportSelection: true
      })
    } else {
      setAuthFlg({
        fileName: '商品一覧（全件）.csv',
        noAutoBOM: false,
        blobType: 'text/plain;charset=Shift-JIS',
        exportAll: true,
        onlyExportSelection: false
      })
    }
  }

  const MyExportCSV = (props) => {
    const handleBlur = () => {
      handleSetAuthFlg(1)
    };
    const handleClick = () => {
      props.onExport();
    };
    return (
      <div>
        <button className="btn btn-secondary btn-lg" onMouseEnter={handleBlur} onClick={handleClick}>CSV出力（チェックのみ）</button>
      </div>
    );
  };
  // const exportCSV = [{
  //   fileName: '商品一覧.csv',
  //   noAutoBOM: false,
  //   blobType: 'text/csv;charset=ansi',
  //   //blobType : 'text/plain;charset=utf-8',
  //   exportAll: true,
  //   onlyExportSelection: false
  // }]
  const MyExportCSV2 = (props) => {
    const handleBlur = () => {
      handleSetAuthFlg(2)
    };
    const handleClick = () => {
      props.onExport();
    };
    return (
      <div>
        <button className="btn btn-secondary btn-lg" onMouseEnter={handleBlur} onClick={handleClick}>CSV出力（全件）</button>
      </div>
    );
  };

  const theme = useTheme();
  const [priceGroup, setPriceGroup] = React.useState('');
  const initialAgencyPrice = { Invs: [] };
  const reducerAgencyPrice = (state, action) => {
    let obj = [];
    switch (action.type) {
      case QUERY:
        for (let i = 0; i < action.Invs.length; i++) {
          obj.push(action.Invs[i].Admin_Agency_Price_Group)
        }
        setPriceGroup(obj[0]);
        return { ...state, Invs: obj };
      default:
        return state;
    }
  };
  let [selectAgencyPriceOptions, setAgencyPriceGroup] = useReducer(reducerAgencyPrice, initialAgencyPrice);

  async function listInvData2(agentGroupID) {
    // get the Login user infomation. Set to user
    // const { accessToken } = await Auth.currentSession();
    // const cognitogroups = accessToken.payload['cognito:groups'];
    // const userAuth = cognitogroups[0];
    setInv({ type: QUERY, Invs: [] });
    const InvData = await API.graphql(graphqlOperation(agencyGroupSortList, { Agency_Price_Group: agentGroupID, sortDirection: "DESC", limit: 10000 }));
    let nextToken = InvData.data.AgencyGroupSortList.nextToken;
    let dataAll = InvData.data.AgencyGroupSortList.items
    while (nextToken !== null) {
      let InvDataSec = await API.graphql(graphqlOperation(agencyGroupSortList, { Agency_Price_Group: agentGroupID, sortDirection: "DESC", limit: 10000, nextToken: nextToken }));
      nextToken = InvDataSec.data.AgencyGroupSortList.nextToken;
      for (let i = 0; i < InvDataSec.data.AgencyGroupSortList.items.length; i++) {
        dataAll.push(InvDataSec.data.AgencyGroupSortList.items[i]);
      }
    }
    let allSupplier = [];
    for (let i = 0; i < dataAll.length; i++) {
      allSupplier.push(dataAll[i].Supplier);
    }
    let distinctSupplier = []
    let hash = {}
    distinctSupplier = allSupplier.reduce((item, next) => {
      hash[next] ? '' : hash[next] = true && item.push(next)
      return item
    }, [])
    setSupplier({ type: QUERY, Invs: distinctSupplier });
    setInv({ type: QUERY, Invs: dataAll });
  }

  const handleChange = (event) => {
    setPriceGroup(event.target.value);
    listInvData2(event.target.value);
  };
  return (
    <Container style={{ backgroundColor: '', 'minWidth': '85vw', 'minHeight': '74vh' }}>
      <div>
        <Meta title={pageTitle} />
      </div>
      <br />
      <Row>
        <Col>
          <ToolkitProvider
            keyField="id"
            data={inventories.Invs}
            columns={columns}
            search
            columnToggle
            headerWrapperClasses="foo"
            wrapperClasses="boo"
            exportCSV={authFlg}
          >
            {
              props => (
                <div>
                  <div>
                    <Row>
                      <Col>
                        <SearchBar className="form-control" style={{ backgroundColor: 'pink', 'fontSize': '18px', height: '40px', padding: '10px 16px', 'lineHeight': '1.3333333', 'borderRadius': '6px' }} {...props.searchProps} />
                        <ClearSearchButton className="btn btn-success btn-lg" {...props.searchProps} />
                      </Col>
                      {/* <ExportCSVButton className="btn btn-secondary btn-lg" {...props.csvProps} >CSV出力（チェックのみ）</ExportCSVButton>
                          <ExportCSVButton className="btn btn-secondary btn-lg" {...props.csvProps} >CSV出力（全件）</ExportCSVButton> */}
                      <Col>
                        <MyExportCSV className="btn btn-secondary btn-lg" {...props.csvProps} ></MyExportCSV ></Col>
                      <Col>
                        <MyExportCSV2 className="btn btn-secondary btn-lg" {...props.csvProps} ></MyExportCSV2 >
                      </Col>
                      <Col>
                          <FormControl hidden={adminAuthFlg} fullWidth>
                            <InputLabel id="demo-simple-select-label">価格グループ</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={priceGroup}
                              onChange={handleChange}
                              input={<OutlinedInput label="Name" />}
                              MenuProps={MenuProps}
                              autoWidth
                            >
                              {selectAgencyPriceOptions.Invs.map((name) => (
                                <MenuItem
                                  key={name}
                                  value={name}
                                  style={getStyles(name, priceGroup, theme)}
                                >
                                  {name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Col>
                    </Row>
                  </div>
                  <hr />
                  <BootstrapTable
                    bootstrap4
                    keyField='id'
                    data={inventories.Invs}
                    caption="表示価格は税別"
                    columns={columns}
                    {...props.baseProps}
                    pagination={paginationFactory(options)}
                    defaultSorted={defaultSorted}
                    selectRow={selectRow}
                    filter={filterFactory()}
                    defaultSortDirection="desc"
                    filterPosition='inline'
                    headerWrapperClasses="foo"
                    wrapperClasses="boo"
                    noDataIndication={'no results found'}
                  />
                  <hr />
                  <span>表記させる項目をこのアイコンでお選び頂けます</span> <br />
                  <CustomToggleList {...props.columnToggleProps} />
                  <hr />
                </div>
              )
            }
          </ToolkitProvider>

        </Col>
      </Row>
    </Container >

  );
}

export default InventorySearch
